export const WORDS = [
	'galah',
	'robin',
	'quail',
	'finch',
	'magpie',
	'raven',
	'goose',
	'booby',
	'drake',
	'hobby',
	'kites',
	'noddy',
	'bower',
	'egret',
	'stilt',
	'eagle',
	'grebe',
	'mynah',
	'crane',
	'bazza',
	'crake',
	'wader',
	'macaw',
	'heron'
]
